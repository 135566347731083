import React from 'react';
import { Container } from 'react-bootstrap';
import loadable from '@loadable/component';

import './assets/styles/_index.scss'


const TeamCard = loadable(() => import("../TeamCard/TeamCard"));
const LandingPagePagination = loadable(() => import("../InnerPagination/LandingPagePagination"));

const { usePagination } = require("@starberry/gatsby-theme-utils/Hooks/UsePagination")


const TeamLanding = ({allTeam}) => {
    // for pagination
    const itemsPerPage = 24
    const { currentItems, currentPage, setCurrentPage } = usePagination({
        items: allTeam,
        itemsPerPage,
    })
    // for pagination

    return (
        <div className='team-landing-section'>
            <Container>
                <div className='team-landing-wrapper'>
                    {currentItems && currentItems.map(({node}, index) => {
                        return (
                            <div key={index}>
                                <TeamCard {...node} />
                            </div>
                        )
                    })}
                </div>
                <LandingPagePagination
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={allTeam?.length}
                    setCurrentPage={setCurrentPage}
                />
            </Container>
        </div>
    )
}

export default TeamLanding